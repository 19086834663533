import styled from "styled-components";
import React, {Fragment} from "react";
import {Close} from "@mui/icons-material";
import {ButtonSecondary} from "components/Buttons";
import {Exist} from "components/Exist";

export const Overlay = styled.div`
	background: rgba(0, 0, 0, 0.5);
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 50;
	left: 0;
	top: 0;
`;

export const Modal = styled.div`
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: calc(100% - 20px);
	max-height: calc(100% - 20px);
	overflow: auto;
	max-width: 600px;
	min-height: 200px;
	background: #fff;
	border-radius: 5px;
	box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
	z-index: 51;
`;

export const ModalInner = styled.div`
	position: relative;
	padding: 25px;
`;

export const ModalContent = styled.div`
	color: #343232;
	text-align: center;
`;

export const CloseArea = styled.button`
	border: 0;
	background: none;
	padding: 0;
	margin: 0;
	cursor: pointer;
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	right: 10px;
	top: 10px;
	z-index: 1;
`;

interface ICloseButtonProps {
	onClick: () => void;
	className?: string;
}

export const CloseButton: React.FC<ICloseButtonProps> = (props) => (
	<CloseArea {...props}>
		<Close />
	</CloseArea>
);

export const ButtonsBox = styled.div`
	padding-top: 40px;
	display: flex;
	align-items: center;
	justify-content: center;

	button,
	a {
		margin: 0 10px;
		width: 170px;

		@media (max-width: 440px) {
			width: 80%;
			margin: 5px 0;
		}
	}

	@media (max-width: 440px) {
		flex-direction: column;
	}
`;

export const ModalTitle = styled.h4`
	font-size: 20px;
	line-height: 22px;
	margin-bottom: 13px;
	color: #1e1450;
`;

export const ModalText = styled.p`
	color: #1e1450;
	font-size: 16px;
	line-height: 24px;

	b {
		font-weight: bold;
	}
`;

export const ModalLogo = styled.img`
	width: 80px;
	height: auto;
	margin-bottom: 15px;
`;

interface IModalWindow {
	onClose: () => void;
	title?: string;
	message?: string;
	buttonText?: string;
	iconSrc?: string;
}

export const ModalWindow: React.FC<IModalWindow> = (props) => {
	const {message, title, onClose, buttonText, iconSrc} = props;

	if (!message) {
		return null;
	}

	return (
		<Fragment>
			<Overlay onClick={onClose} />
			<Modal>
				<ModalInner>
					<CloseButton onClick={onClose} />
					<ModalContent>
						<Exist when={Boolean(iconSrc)}>
							<ModalLogo src={iconSrc} alt="" role="banner" />
						</Exist>
						<Exist when={Boolean(title)}>
							<ModalTitle>{title}</ModalTitle>
						</Exist>
						<ModalText>{message}</ModalText>
						<Exist when={Boolean(buttonText)}>
							<ButtonsBox>
								<ButtonSecondary onClick={onClose}>{buttonText}</ButtonSecondary>
							</ButtonsBox>
						</Exist>
					</ModalContent>
				</ModalInner>
			</Modal>
		</Fragment>
	);
};
